import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Button } from "reactstrap";
import {
  getFilteredTargetGpisAction,
  getDrugCategory,
  clearDrugCategory,
  populateSidebar,
  populateBreadCrumbs,
  getNDCPricesAction,
  clearNdcPrices,
} from "../../../actions";
import "./TargetCategories.css";
import _ from "lodash";
import {
  getDrugName,
  gpiNameFormatter,
  Role,
  optionalCostFormatter,
  pairingTypeFormatter,
  fullYesNoFormatter,
  getDrugNameCSSClass,
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import NDCPriceModal from "../../Modal/NDCPriceModal";
import { getClinicalSidebarItems } from "../Constants";
import TargetedGPIsSearchForm from "./TargetedGPIsSearchForm";
import { DateTime } from "luxon";

class TargetedGPIs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbsLoaded: false,
      categoryId: parseInt(this.props.match.params.categoryId),
      webpageName: "TargetedGPIs",
      ndcPriceModalOpen: false,
      isAuthorized: _.some(this.props.auth.currentUser.roles, {
        name: Role.CLINICIAN.name,
      }),
      isApprovalAuthorized: _.some(this.props.auth.currentUser.roles, {
        name: Role.CLINICAL_APPROVER.name,
      }),
      currPage: 1,
      sizePerPage: 15,
      searchTerms: {
        effectiveDate: DateTime.now().toISODate(),
        categoryId: parseInt(this.props.match.params.categoryId),
        names: [],
        gpis: [],
        mscs: [],
      },
      drugData: {
        gpi: "",
        msc: "",
        commonDrugName: "",
      },
    };
  }

  formatNamesAndTooltips = (targetGpis) => {
    targetGpis.forEach((tgpi) => {
      tgpi.targetGpiName = getDrugName(tgpi);

      _.forEach(tgpi.alternateGpis, (alt) => {
        alt.ndcPairingOpportunity = alt.ndcPairingOpportunity ? "Yes" : "No";
        alt.name = getDrugName(alt);
        alt.targetGpi = tgpi.gpi.gpi;
        alt.targetMultiSource = tgpi.multiSource;
        alt.targetDacon = tgpi.dacon;
      });
    });
    return targetGpis;
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/targetCategories", name: "Clinical Catalog", active: false },
      ],
      finalCrumb: this.props.category.description,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.getPage();
    this.props.getDrugCategory(this.state.categoryId);
    this.props.populateSidebar(
      getClinicalSidebarItems(this.props.auth.currentUser.roles),
    );
  }

  componentWillUnmount() {
    this.props.clearDrugCategory();
  }

  componentDidUpdate() {
    var gpis = this.props.gpis;
    if (gpis && this.props.category) {
      if (!this.state.breadcrumbsLoaded) {
        this.setState({ breadcrumbsLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  ALT_COLUMNS = [
    {
      dataField: "pairingType",
      text: "Pairing Type",
      headerAlign: "center",
      align: "center",
      formatter: pairingTypeFormatter,
    },
    {
      dataField: "pairNewStartsOnly",
      text: "Pair NSO",
      formatter: fullYesNoFormatter,
    },
    {
      dataField: "name",
      text: "Drug Name",
      sort: true,
      formatter: (_, row) => {
        const drugName = row.ndc ? row.ndcName.formattedDrugName : row.name;
        const classes =
          row.newStartsOnly === "Y"
            ? "align-baseline nso-drug-name"
            : "align-baseline";

        return (
          <Button
            className={classes}
            color="link"
            onClick={() => this.handleAlternateOnClick(row)}
          >
            {drugName}
          </Button>
        );
      },
    },
    {
      dataField: "dacon",
      text: "DACon",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "multiSource",
      text: "MSC",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "adminType",
      text: "Admin.",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "courseDayCount",
      text: "DpC",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "totalCost",
      text: "Ingr. Cost",
      formatter: (content) => {
        return optionalCostFormatter(content);
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  altsFormatter = (content) => {
    return (
      <RPSDataGrid keyField="id" data={content} columns={this.ALT_COLUMNS} />
    );
  };

  typeFormatter = (content) => {
    const type = content ? content : "null";
    return <span>{type}</span>;
  };

  getNdcPrices = (
    targetGpi,
    targetMsc,
    targetDacon,
    altGpi,
    altMsc,
    altDacon,
  ) => {
    this.props.getNDCPricesAction(
      targetGpi,
      targetMsc,
      targetDacon,
      altGpi,
      altMsc,
      altDacon,
    );
  };

  toggleNdcPriceModal = () => {
    if (this.state.ndcPriceModalOpen) {
      this.props.clearNdcPrices();
    }
    this.setState({
      ndcPriceModalOpen: !this.state.ndcPriceModalOpen,
    });
  };

  targetNameFormatter = (content, row) => {
    const drugName = row.ndc
      ? row.ndcName.formattedDrugName
      : gpiNameFormatter(content, row);
    return (
      <Button
        className={getDrugNameCSSClass(row.newStartsOnly)}
        color="link"
        onClick={() => this.handleTargetOnClick(row)}
      >
        {drugName}
      </Button>
    );
  };

  handleTargetOnClick = (row) => {
    const drugData = {
      gpi: row.gpi.gpi,
      msc: row.multiSource,
      commonDrugName: row.drugName.commonName,
      ndc: row.ndc,
    };

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.gpi.gpi, row.multiSource, row.dacon);
    this.toggleNdcPriceModal();
  };

  handleAlternateOnClick = (alt) => {
    const drugData = {
      gpi: alt.gpi.gpi,
      msc: alt.multiSource,
      commonDrugName: alt.name,
      ndc: alt.ndc,
    };

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(
      alt.targetGpi,
      alt.targetMultiSource,
      alt.targetDacon,
      alt.gpi.gpi,
      alt.multiSource,
      alt.dacon,
    );
    this.toggleNdcPriceModal();
  };

  getPage = () => {
    this.props.getFilteredTargetGpisAction(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField === "targetGpiName") {
        sortField = "drugName.commonName";
      }

      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "targetGpiName",
      sort: true,
      text: "Targeted Drug Name",
      formatter: this.targetNameFormatter,
    },
    {
      dataField: "dacon",
      sort: false,
      text: "DACon",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "multiSource",
      sort: false,
      text: "MSC",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "adminType",
      sort: false,
      text: "Administered By",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "courseDayCount",
      sort: false,
      text: "Days per Course",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "totalCost",
      text: "30 Day (Per Claim) Ingr. Cost",
      formatter: (content) => {
        return optionalCostFormatter(content);
      },
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "7%" },
    },
    {
      dataField: "alternateGpis",
      sort: false,
      text: "Assigned Alternative(s)",
      formatter: this.altsFormatter,
    },
  ];

  render() {
    if (_.isEmpty(this.props.gpis) || _.isEmpty(this.props.category)) {
      return <div>Loading...</div>;
    } else if (this.props.gpis.length === 0) {
      return (
        <div className="warning-banner">No targets for this target group.</div>
      );
    }

    const formattedGpis = this.formatNamesAndTooltips(this.props.gpis.content);

    const drugCategoryName = this.props.category.description;

    return (
      <Container className="main-container" fluid>
        <Row>
          <h3>{drugCategoryName}</h3>
        </Row>
        <Row className="mt-3">
          <Col>
            <TargetedGPIsSearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>
        <Row className="react-bootstrap-2 mt-3">
          <Col>
            <RPSDataGrid
              remote
              keyField="id"
              data={formattedGpis}
              columns={this.COLUMNS}
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.gpis.totalElements}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <NDCPriceModal
          isOpen={this.state.ndcPriceModalOpen}
          toggle={this.toggleNdcPriceModal}
          ndcPrices={this.props.ndcPrices}
          clearAction={this.props.clearNdcPrices}
          drugData={this.state.drugData}
          showSequence={true}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    gpis: state.gpis,
    ndcPrices: Object.values(state.ndcPrices),
    category: state.drugCategories.category,
  };
};

export default connect(mapStateToProps, {
  getFilteredTargetGpisAction,
  getDrugCategory,
  clearDrugCategory,
  populateSidebar,
  populateBreadCrumbs,
  getNDCPricesAction,
  clearNdcPrices,
})(TargetedGPIs);
